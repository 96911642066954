var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pro-menu-layout',[_c('div',{staticClass:"q-pa-md"},[_c('pro-deck',{attrs:{"title":_vm.getRes('Document.RegisterSiteVisit'),"cards":_vm.registeredList},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var registeredTenderer = ref.card;
return [_c('pro-deck-card',{attrs:{"title":registeredTenderer.cmpyNameEng,"actions":registeredTenderer.tenderBriefingId <= 0
            ? []
            : _vm.deckCardList(
                registeredTenderer.tendererId,
                registeredTenderer.tenderBriefingId,
                registeredTenderer.id <= 0
              ),"disable":registeredTenderer.removed,"expandable":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto"},[_c('pro-key-value-table',{attrs:{"rows":_vm.tendererTableRows,"data":registeredTenderer}})],1)])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }